import React from 'react'

function Profile({name,intro,score,index,}) {
    return (
        <div className='flex w-[100%] bg-purple-300 rounded-md'>
            <div className='text-3xl mr-2 bg-zinc-600 p-3 text-white'>
                {index}
            </div>
            <div className='flex justify-center items-center'>
                {/* <img className="h-9 w-9 rounded-full" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                <div className="ml-2 overflow-hidden">
                    <p className="text-md font-medium text-slate-900">{name}</p>
                    <p className="text-md truncate text-yellow-900">{intro}</p>
                </div>
                <div className="absolute right-14 mt-1 text-2xl">
                    {score}x
                </div>
            </div>
        </div>
    )
}

export default Profile