import React from 'react'

function Instruction() {
    return (
        <div className='flex flex-col w-[100%]'>
            <h1 className='text-xl bg-cyan-300 p-2'>Rules of Game</h1>
            <ul className='list-decimal list-inside mt-2 text-md p-2'>
                <li>Your score = Number of push-ups in 30 sec.</li>
                <li>Place your mobile as shown below</li>
                <img src='/demo.png' alt='pose' border='0' />
            </ul>
        </div>
    )
}

export default Instruction