import React from 'react'
import Popup from 'reactjs-popup'

function InstructionModal({ open }) {
    return (
        <Popup className="mt-0 flex flex-col justify-center items-center " open={open} modal>
            <div className="flex flex-col justify-center items-center rounded-lg shadow-lg bg-slate-600 w-[100%] h-[300%]">
                <img src="/allow-demo.png" alt="Allow Camera" className="flex felx-col justify-center mt-10 text-3xl text-white w-[90%] rounded-md"/>
                <h1 className="flex felx-col justify-center text-center mt-5 mb-10 text-3xl text-white">Allow Camera permission to track your Pusp-ups</h1>
            </div>
        </Popup>
    )
}

export default InstructionModal