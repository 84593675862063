import React, { useEffect } from 'react'
import Profile from './Profile'
import axios from 'axios'
import { useState } from 'react';


function LeaderBoard({count}) {



    const [data, setData] = useState([]);



    // content type application in axios
    useEffect(() => {
        axios.post('https://asia-south1-wakeup-at-5amclub.cloudfunctions.net/getTopScores', { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                console.log(res.data);
                setData(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    },[])

    return (
        <div className='flex flex-col justify-center'>
            <div className='ml-6 mt-5 text-xl'>LeaderBoard </div>
            <hr className='w-[90%] ml-auto mr-auto text-purple-400'></hr>
            <ul className="p-6 ">
                {
                    data.map((item, index) => {
                        if (item.score > 0)
                            return (
                                <li className="flex py-2 first:pt-0 last:pb-0">
                                    <Profile index={index} name={item.name} score={item.score} intro={item.intro} image={item.image} />
                                </li>
                            );
                    }, [])
                }
            </ul>
        </div>
    );
}

export default LeaderBoard