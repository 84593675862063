import React from 'react'
import Popup from 'reactjs-popup';
import { useState } from 'react';

function ScoreModal({ open, score }) {

    // var innerOpen state
    var [innerOpen, setInnerOpen] = useState(true);

    // Loading state
    var [loading, setLoading] = useState(false);

    // logic to submit form using post on url
    const handleSubmit = (e) => {
        
        e.preventDefault();
        const form = document.querySelector('form');

        const data = {
            "name": form.name.value,
            "intro": form.intro.value,
            "email": form.email.value,
            "score":  parseInt(form.score.value),
            "image": form.image.value,
          };
        
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
        
          var raw = JSON.stringify(data);
        
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          };
        
          setLoading(true);
          fetch("https://asia-south1-wakeup-at-5amclub.cloudfunctions.net/submitScore", requestOptions)
            .then(response =>{
                console.log(response.text());
                setInnerOpen(false);
                setLoading(false);
                window.location.replace("https://fit.5amclub.co.in/?type=2");
                // window.location.replace("http://localhost:3000/?type=2");
                return response.text();
            })
            .catch(error =>{
                setInnerOpen(false);
                setLoading(false);
                window.location.replace("https://fit.5amclub.co.in/?type=2");
                // window.location.replace("http://localhost:3000/?type=2");
                
                console.log('error', error)
            });
    }

    return (
        <Popup open={(innerOpen && open)} modal>
            <div className='flex flex-col bg-white w-[90vw] justify-center items-center border-2 border-cyan-300 bg-purple-300 shadow-lg'>
                <h1 className='text-2xl mb-2 mt-4'>Your Score : {score}</h1>
                <h2 className='text-md'>Submit your score to the leaderboard</h2>
                <hr></hr>
                {/* name email intro profile picture submit form */}
                <form className='flex flex-col justify-center items-center'>
                    <input type='hidden' name='score' value={score}></input>
                    <input type='text' name='name' placeholder='Name' className='border-2 border-cyan-300 p-1 rounded-sm shadow-sm mt-2'></input>
                    <input type='email' name='email' placeholder='Email' className='border-2 border-cyan-300 p-1 rounded-sm shadow-sm mt-2'></input>
                    <textarea placeholder='Intro' name='intro' className='border-2 border-cyan-300 p-1 rounded-sm shadow-sm mt-2 w-[100%]'></textarea>
                    <input type='file' placeholder='Image' name='image' hidden className='border-2 border-cyan-300 p-1 rounded-sm shadow-sm mt-2 w-[100%]'></input>
                    <button disabled={loading} type='submit' onClick={handleSubmit} className='bg-cyan-300 p-1 rounded-sm shadow-sm mt-2 w-[100%] text-xl mb-9'>Submit</button>
                </form>
                {loading && <div className='text-lg'>Loading...</div>}
            </div>
        </Popup>
    )
}

export default ScoreModal