import Webcam from 'react-webcam';
import './App.css';
import LeaderBoard from './componenets/LeaderBoard';

// medapipe imports
import { Pose } from '@mediapipe/pose'
import { POSE_CONNECTIONS } from '@mediapipe/pose';
import * as cam from '@mediapipe/camera_utils';
import { useRef, useEffect, useState } from 'react';
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import Counter from './componenets/Counter';
import Instruction from './componenets/Instruction';
import Timer from './componenets/Timer';
import ScoreModal from './componenets/ScoreModal';
import InstructionModal from './componenets/InstructionModal';


function App() {

  const queryParameters = new URLSearchParams(window.location.search)
  const type = queryParameters.get("type")


  const webCamRef = useRef(null);
  const canvasRef = useRef(null);
  let camera = null;
  console.log("webCamRef : ", webCamRef);


  // Postion Tracking
  const [mode, setMode] = useState("Instruction");
  const mm = useRef("Instruction");
  const [timeLeft, setTimeLeft] = useState(30);

  const count = useRef(0);
  const position = useRef("up");
  var [cc, setCc] = useState(0);

  var [modalOpen, setModalOpen] = useState(false);

  // instruction modal
  var [imodal, setImodal] = useState(true);


  // refesh leaderboarf
  const refresh = useRef(0);

  function startWorkout() {
    setMode("Workout");
    mm.current = "Workout";
    setTimeLeft(30);
    setCc(0);
    count.current = 0;
    console.log(mode);
  }

  function stopWorkout() {
    setMode("Instruction");
    mm.current = "Instruction";
    console.log(mode);
  }

  function completedWorkout() {
    setMode("Completed");
    mm.current = "Completed";
    var score = count.current;
    setModalOpen(true);
    console.log(mm.current, "hh");

  }

  const onResults = (results) => {
    const videoWidth = webCamRef.current.video.videoWidth;
    const videoHeight = webCamRef.current.video.videoHeight;

    //Sets height and width of canvas 
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");

    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    if (results.poseLandmarks) {

      drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS,
        { color: '#00FF00', lineWidth: 4 });
      drawLandmarks(canvasCtx, results.poseLandmarks,
        { color: '#00ffd0', lineWidth: 2 });

      var lmList = results.poseLandmarks;

      // Close Insturction modal
      if (lmList[0].y !== undefined && imodal) {
        setImodal(false);
      }

      // Algo 1
      // if ((lmList[14].y <= lmList[12].y) && (lmList[13].y <= lmList[11].y)) {
      //   position.current = "down";

      // }
      // if ((lmList[14].y >= lmList[12].y) && (lmList[13].y >= lmList[11].y)) {
      //   if (position.current === "down") {
      //     console.log("up", mm.current);
      //     if (mm.current === "Workout") {
      //       count.current = count.current + 1;
      //       setCc(count.current);
      //     }
      //     position.current = "up";
      //   }
      // }

      // Algo 2
      if ((lmList[0].y <= lmList[14].y) && (lmList[0].y <= lmList[13].y)) {
        position.current = "down";
      }
      if ((lmList[0].y >= lmList[14].y) && (lmList[0].y >= lmList[13].y)) {
        if (position.current === "down") {
          console.log("up", mm.current);
          if (mm.current === "Workout") {
            count.current = count.current + 1;
            setCc(count.current);
          }
          position.current = "up";
          // closing instruction modal
        }
      }

    }

    canvasCtx.restore();
  }

  useEffect(() => {
    const pose = new Pose({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
      },
    });

    pose.setOptions({
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: true,
      smoothSegmentation: true,
      minDetectionConfidence: 0.7,
      minTrackingConfidence: 0.7
    });

    pose.onResults(onResults);


    if (typeof webCamRef.current !== 'undefined' && webCamRef.current !== null) {
      camera = new cam.Camera(webCamRef.current.video, {
        onFrame: async () => {
          await pose.send({ image: webCamRef.current.video })
        }
      });
      camera.start();



    }
  }, []);


  // timer countdown useEffect
  useEffect(() => {
    if (timeLeft > 0 && mode === "Workout") {
      setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    };
    if (timeLeft === 0 && mode === "Workout") {
      completedWorkout();
    }
  }, [timeLeft, mode]);


  return (
    <>
      <InstructionModal open={imodal} />
      <div className="felx flx-col justify-star">
        <div className="felx flx-row justify-start items-center">
          <h1 className="flex felx-col justify-center mt-5 text-3xl">Push Up Challenge 💪</h1>
          <h6 className="flex felx-col justify-center mt-2 text-md">
            <a href='https://5amclub.co.in'>The 5amclub.co.in Production</a>
          </h6>
          <hr className='mt-2'></hr>
          <div className='flex flex-col justify-center items-start mt-0'>
            {((mode === "Instruction") && type === null) &&
              <div className='flex flex-col justify-center items-start mt-0 mb-2 w-[100%]'>
                {/* <Webcam ref={webCamRef} /> */}
                <Instruction />
                <button onClick={startWorkout} className="bg-cyan-300 p-2 rounded-md shadow-sm ml-3">Start Now</button>
              </div>
            }
            <div>
              {/* {(((mode === "Workout")) && */}
              <>
                <Webcam ref={webCamRef} className='hidden' />
                <div className='flex justify-between p-2 pt-0 bg-purple-300'>
                  <Counter count={cc} />
                  <Timer timeLeft={timeLeft} />
                </div>
                <canvas
                  ref={canvasRef}
                  className="w-[100%]"
                />
              </>
              {/* )} */}
            </div>
          </div>
          <div className="flex felx-col justify-centermt-0 p-2 text-xl gap-4 bg-purple-300">
            {(type != null) &&
              <button onClick={startWorkout} className="bg-cyan-300 p-2 rounded-md shadow-sm ml-3">One More Round</button>
            }
          </div>
          {/* <hr className='w-[90%] ml-[5%] mt-4'></hr> */}
          <div>
            <LeaderBoard count={count.current} />
          </div>
        </div>
        <ScoreModal open={modalOpen} score={count.current} />
      </div>
    </>
  );
}

export default App;